import {
  Container,
  Row,
  Col,
  TabContainer,
  TabContent,
  TabPane,
} from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import { ProjectCard } from "./ProjectCard";
import { projects } from "./data.js";

export const Projects = () => {
  const web = projects.filter((project) => project.topic === "web");
  const graphic = projects.filter((project) => project.topic !== "web");

  return (
    <section className="projects" id="projects">
      <Container>
        <Row>
          <Col>
            <h2>Projects</h2>
            <p>
              Here is a short wrap up of my projects where I used all skills
              that I aquired over the past years.
            </p>
            <TabContainer id="projects-tabs" defaultActiveKey={"link-1"}>
              <Nav
                variant="pills"
                className="nav-pills mb-5 justify-content-center align-items-center"
                id="pills-tab"
                activeKey="link-1"
              >
                <Nav.Item>
                  <Nav.Link eventKey="link-1">
                    Web Design / Development
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="link-2">Graphic Design</Nav.Link>
                </Nav.Item>
              </Nav>
              <TabContent className="justify-content-center">
                <TabPane eventKey="link-1">
                  <Row>
                    {web.map((projetc, index) => {
                      return (
                        <ProjectCard key={index} {...projetc}></ProjectCard>
                      );
                    })}
                  </Row>
                </TabPane>
                <TabPane eventKey="link-2">
                  <Row>
                    {graphic.map((project, index) => {
                      return (
                        <ProjectCard key={index} {...project}></ProjectCard>
                      );
                    })}
                  </Row>
                </TabPane>
              </TabContent>
            </TabContainer>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
