import { Col } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import React, { useState } from "react";

export const ProjectCard = ({ title, description, imgURL, imgURLs }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [fullscreen, setFullscreen] = useState(true);
  const images = imgURLs.map(function (image) {
    return <img src={image} rounded />;
  });

  return (
    <>
      <Col sm={6} md={4}>
        <Card onClick={handleShow}>
          <Card.Img variant="top" src={imgURL} />
          <Card.Body>
            <Card.Title>{title}</Card.Title>
            <Card.Text>{description}</Card.Text>
          </Card.Body>
        </Card>
      </Col>

      <Modal show={show} fullscreen={fullscreen} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body onClick={handleClose}>{images}</Modal.Body>
      </Modal>
    </>
  );
};
