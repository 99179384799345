import proj1thumb from "../assets/img/project-1-thumb.png";
import proj1home from "../assets/img/project-1-homepage.png";
import proj2thumb from "../assets/img/project-2-thumb.png";
import proj2home from "../assets/img/project-2-homepage.png";
import proj3thumb from "../assets/img/project-3-thumb.png";
import proj3home from "../assets/img/project-3-homepage.png";
import proj4thumb from "../assets/img/project-4-thumb.png";
import proj4home from "../assets/img/project-4-homepage.png";
import proj5thumb from "../assets/img/project-5-thumb.png";
import proj5home from "../assets/img/project-5-homepage.png";
import proj6thumb from "../assets/img/project-6-thumb.png";
import proj6home from "../assets/img/project-6-homepage.png";
import proj7thumb from "../assets/img/project-7-thumb.png";
import proj7home from "../assets/img/project-7-homepage.png";
import proj8thumb from "../assets/img/project-8-thumb.png";
import proj8home from "../assets/img/project-8-homepage.png";
import proj9thumb from "../assets/img/project-9-thumb.png";
import proj9home from "../assets/img/project-9-homepage.png";
import proj10thumb from "../assets/img/project-10-thumb.png";
import proj10home from "../assets/img/project-10-homepage.png";
import proj11thumb from "../assets/img/project-11-thumb.png";
import proj11home from "../assets/img/project-11-homepage.png";
import proj12thumb from "../assets/img/project-12-thumb.png";
import proj12home from "../assets/img/project-12-homepage.png";
import proj13thumb from "../assets/img/project-13-thumb.png";
import proj13home from "../assets/img/project-13-homepage.png";

export const projects = [
  {
    title: "Helpling Homepage Design",
    description:
      "2022 - I redesigned the overall web appearance of the Helpling company and implemented the Design in Wordpress.",
    imgURL: proj1thumb,
    imgURLs: [proj1home],
    topic: "web",
  },
  {
    title: "Mangashelf Homepage Design",
    description:
      "2023 - I designed the web appearance of a fictional digital Manga bookshelf application that I would like to implement.",
    imgURL: proj6thumb,
    imgURLs: [proj6home],
    topic: "web",
  },
  {
    title: "TFS Landing Page Design",
    description:
      "2022 - The Design of the TFS brand has been updated. I adpated the extisting landingpages to these Design Guidelines.",
    imgURL: proj2thumb,
    imgURLs: [proj2home],
    topic: "web",
  },
  {
    title: "The CryptoSea Homepage Design",
    description:
      "2021 - A private NFT project that me and some friends launched. I created the Design and implemented the React App to buy NFTs",
    imgURL: proj3thumb,
    imgURLs: [proj3home],
    topic: "web",
  },
  {
    title: "Haushaltsgenie Homepage Design",
    description:
      "2020 - I designed the overall web appearance of the Haushaltsgenie Brand and implemented the Design in Wordpress.",
    imgURL: proj4thumb,
    imgURLs: [proj4home],
    topic: "web",
  },
  {
    title: "Haushaltsgenie Homepage Design",
    description:
      "2017 - A Design for the Helpling Spring Campaign. Designed in Adobe and implemented via HTML/CSS.",
    imgURL: proj5thumb,
    imgURLs: [proj5home],
    topic: "web",
  },
  {
    title: "Helpling Out of Home",
    description:
      "2022 - A Out of Home creative that is based on the new Helpling Website.",
    imgURL: proj7thumb,
    imgURLs: [proj7home],
    topic: "graphic",
  },
  {
    title: "Helpling Print Materials",
    description:
      "2022 -  A range of print materials used for Helpling campaigns in several countries.",
    imgURL: proj12thumb,
    imgURLs: [proj12home],
    topic: "graphic",
  },
  {
    title: "Helpling Dare the Mess Campaign",
    description:
      "2018 - A set of Billboards that belong to Helpings 'Dare the Mess' Campaign in Germany.",
    imgURL: proj8thumb,
    imgURLs: [proj8home],
    topic: "graphic",
  },
  {
    title: "Gel Lyte 3 Hommage",
    description:
      "2022 - A set of art pieces dedicated to my favorite Sneaker Silhouette - Asics Gel Lyte 3.",
    imgURL: proj13thumb,
    imgURLs: [proj13home],
    topic: "graphic",
  },
  {
    title: "BLAST Festival",
    description:
      "2012 - Bachelor Thesis - Corporate Design for a fictional Rock Festival",
    imgURL: proj10thumb,
    imgURLs: [proj10home],
    topic: "graphic",
  },
  {
    title: "Illustration Project",
    description:
      "2015 - Illustrator illustration set describing the main points of a cleaning booking.",
    imgURL: proj11thumb,
    imgURLs: [proj11home],
    topic: "graphic",
  },
];
