import { Container, Row, Col } from "react-bootstrap";

export const Footer = () => {
  return (
    <section className="footer" id="footer">
      <Container>
        <Row className="align-items-center text-center">
          <Col xs={12} md={12} xl={12}>
            <p>©2023 | Fabian Beckmann</p>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
