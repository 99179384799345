import React from "react";
import ReactDOM from "react-dom";
import Tilt from "react-parallax-tilt";
import { Container, Row, Col } from "react-bootstrap";
import headerImg1 from "../assets/img/header-img-01.svg";
import headerImg2 from "../assets/img/header-img-02.svg";
import headerImg3 from "../assets/img/header-img-03.svg";
import headerImg4 from "../assets/img/header-img-04.svg";
import headerImg5 from "../assets/img/header-img-05.svg";

export const Banner = () => {
  return (
    <section className="banner" id="home">
      <Container>
        <Row className="align-items-center">
          <Col xs={12} md={6} xl={7}>
            <div>
              <h1>
                Hi! I am <span>Fabian Beckmann</span>
              </h1>
              <p>
                I am a Graphic Designer and Web Developer from Berlin. For
                several years I have been working in the field Graphic Design
                with <b>Adobe Creative Suite</b> and Web Development with{" "}
                <b>HTML</b>,<b>CSS</b>, <b>Javascript</b> and additionally have
                profound knowledge in <b>Wordpress</b>.
              </p>
            </div>
          </Col>
          <Col xs={12} md={6} xl={5}>
            <div>
              <div className="banner-img-container">
                <div
                  id="shape-1"
                  class="
                  rounded-circle
                  position-absolute
                  opacity-70
                  scale-up-center
                  d-none d-md-block
                  "
                ></div>
                <div
                  id="shape-2"
                  class="
                  rounded-circle
                  position-absolute
                  opacity-70
                  scale-up-center
                  fade-delay-2000
                  d-none d-md-block
                  "
                ></div>
                <img
                  className="banner-img-4"
                  src={headerImg4}
                  alt="Header Img"
                />
                <img
                  className="banner-img-1 rotate"
                  src={headerImg1}
                  alt="Header Img"
                />
                <img
                  className="banner-img-2"
                  src={headerImg2}
                  alt="Header Img"
                />
                <img
                  className="banner-img-5 float"
                  src={headerImg5}
                  alt="Header Img"
                />
                <img
                  className="banner-img-3 scale-up"
                  src={headerImg3}
                  alt="Header Img"
                />
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
